// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Nullable, OrbitApiMeta, Tutor } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useTutorStore = defineStore('tutor', () => {
  // fetch repositories
  const { $orbitApiFetch, $tutors } = useNuxtApp()

  // state
  const tutor = ref<Nullable<Tutor | Partial<Tutor>>>(null)
  const tutors = ref<Tutor[]>([])
  const prioritizedTutors = ref<Tutor[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const { tutors: _tutors } =
      await $tutors.index<{ tutors: Tutor[], meta: OrbitApiMeta }>(config)
    tutors.value = _tutors
    return tutors
  }

  async function show (id: number, config?: NitroFetchOptions<string>) {
    const { tutor: _tutor } =
      await $tutors.show<{ tutor: Tutor }>(id, config)
    tutor.value = _tutor
    return tutor
  }

  async function getPrioritizedTutors (
    config?: NitroFetchOptions<string>
  ) {
    const { tutors } =
      await $orbitApiFetch<{ tutors: Tutor[] }>(
        'v2/tutors/prioritized',
        config
      )
    prioritizedTutors.value = tutors
    return tutors
  }

  return {
    tutor,
    tutors,
    prioritizedTutors,
    getPrioritizedTutors,
    index,
    show
  }
})
